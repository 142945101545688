<script setup>

    import { useQRCode } from '@vueuse/integrations/useQRCode';
    import { EventCategory, PersonalDocumentWithPhotoType } from '../../../../../../../../resources/js/Enums';
    import { useForm } from '@inertiajs/vue3';
    import { inject } from 'vue';

    const props = defineProps({
        ticket: Object,
        errors: Object
    });

    const route = inject('route');

    // 

    const breadcrumbItems = [
        { title: 'Início', to: route('app-site.home') },
        { title: 'Ingressos', to: route('app-site.user.menu.tickets.index') },
        {
            title: 'Ingresso#' + props.ticket.uuid,
            color: 'primary'
        }
    ];

    const ticketQrcode = useQRCode(props.ticket.uuid, {
        width: 400,
        margin: 2
    });

    const ticketUpdateForm = useForm({
        name: props.ticket.name,
        document_type: props.ticket.documentType,
        document_number: props.ticket.documentNumber,
    });

    const ticketTransferForm = useForm({
        email: null,
    });

</script>

<template>
    <v-container style="max-width: 1300px;">
        <v-breadcrumbs :items="breadcrumbItems" class="mb-5"></v-breadcrumbs>

        <v-row class="mb-3">
            <v-col cols="12" lg="8" order="2" order-md="1">
                <v-card flat border class="pa-5">

                    <template v-if="!ticket.accreditedAt" #prepend>
                        <v-icon icon="mdi-pencil"></v-icon>

                    </template>

                    <template v-if="!ticket.accreditedAt" #title>
                        <v-card-title>
                            Editar dados
                        </v-card-title>
                    </template>

                    <v-card-text class="mt-10">
                        <x-form :form="ticketUpdateForm"
                            :action="$route('app-site.user.menu.tickets.update', [ticket.uuid])" #="{ activatorProps }">

                            <v-row>
                                <v-col cols="12">
                                    <v-text-field v-model="ticketUpdateForm.name" label="Nome completo"
                                        class="mb-3"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row class="mb-3">
                                <v-col cols="12" lg="4">
                                    <v-select v-model="ticketUpdateForm.document_type" label="Tipo de documento"
                                        :items="PersonalDocumentWithPhotoType.toSelect($t)"></v-select>

                                </v-col>
                                <v-col cols="12" lg="8">
                                    <v-text-field v-model="ticketUpdateForm.document_number"
                                        label="Número de documento"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-btn :="activatorProps" text="Atualizar" :disabled="!ticketUpdateForm.isDirty"
                                color="primary" prepend-icon="mdi-check"></v-btn>
                        </x-form>
                    </v-card-text>

                    <v-divider class="my-3"></v-divider>

                    <v-card-text>
                        <v-list>
                            <v-list-subheader title="Entrada" class="font-weight-bold"></v-list-subheader>
                            <v-list-item :title="ticket.ticketType.name.toUpperCase()" prepend-icon="mdi-circle-medium"
                                class="mb-3"></v-list-item>

                            <v-divider class="my-3"></v-divider>

                            <v-list-subheader title="Evento"></v-list-subheader>
                            <v-list-item :title="ticket.ticketType.event.title"
                                :to="$route('app-site.events.show', [ticket.ticketType.event?.slug || ticket.ticketType.event.uuid])"
                                prepend-icon="mdi-circle-medium" class="mb-3"></v-list-item>
                            <v-list-item subtitle="Categorias"
                                :title="$t(EventCategory.tryFrom(ticket.ticketType.event.category).name)"
                                :base-color="EventCategory.tryFrom(ticket.ticketType.event.category).mdIcon"
                                prepend-icon="mdi-shape" class="mb-3"></v-list-item>

                            <v-list-item subtitle="Local" :title="ticket.ticketType.event.locationDescription"
                                prepend-icon="mdi-map-marker" class="mb-3"></v-list-item>

                            <v-list-item prepend-icon="mdi-calendar-range">

                                <v-chip v-for="xDate in ticket.ticketType.event.dates" size="small" class="me-1 mb-1"
                                    :variant="$locale.dayjs(xDate.startAt).isSame(new Date, 'day') ? 'flat' : 'tonal'">
                                    {{ $locale.formatDate(xDate.startAt) }}
                                </v-chip>

                                <v-list-item-subtitle>Datas</v-list-item-subtitle>
                            </v-list-item>

                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="4" order="1" order-md="2">

                <v-alert v-if="ticket?.accreditedAt" class="mb-5" color="success" icon="mdi-check" density="compact">
                    <template #text>
                        Você já realizou o check-in no evento ({{ $locale.formatDatetime(ticket.accreditedAt) }}).
                    </template>
                </v-alert>

                <v-card flat border class="pa-0 pa-md-5" title="QRCode e Opções">
                    <v-img :src="ticketQrcode" :alt="ticket?.uuid" class="mx-auto"></v-img>

                    <v-card-text class="mt-10 mt-md-5">

                        <v-dialog v-if="false" persistent width="600">
                            <template #activator="{ props }">
                                <v-btn color="primary" block prepend-icon="mdi-swap-horizontal"
                                    text="Transferir titularidade" class="mb-3" :="props" disabled></v-btn>
                            </template>

                            <template #default="{ isActive }">
                                <v-card flat border class="pa-5" title="Transferir titularidade do ingresso">

                                    <template #append>
                                        <v-icon icon="mdi-close" @click="() => isActive.value = false"></v-icon>
                                    </template>

                                    <v-card-text class="mt-5">
                                        <x-form :form="ticketTransferForm"
                                            :action="$route('app-site.user.menu.tickets.transfer', [ticket.uuid])"
                                            #="{ activatorProps }">

                                            <v-text-field v-model="ticketTransferForm.email"
                                                label="E-mail do usuário que receberá o seu ingresso"
                                                :rules="[$requiredRule, $emailRule]" :error-messages="errors?.email"
                                                class="mb-10"></v-text-field>

                                            <v-btn :="activatorProps" text="Confirmar transferência" color="primary"
                                                prepend-icon="mdi-check" class="mb-3" block></v-btn>

                                        </x-form>
                                    </v-card-text>
                                </v-card>
                            </template>
                        </v-dialog>

                        <v-btn v-if="ticket.sourceId" text="Detalhes do pedido" color="primary"
                            prepend-icon="mdi-open-in-new"
                            :to="$route('app-site.user.menu.orders.show', [ticket.sourceId])" block
                            class="mb-3"></v-btn>


                        <v-btn text="Ingresso" color="primary" prepend-icon="mdi-open-in-new"
                            :href="$route('app-site.events.tickets.show', [ticket.uuid])" block class="mb-3"></v-btn>

                        <v-btn text="Baixar ingresso" color="primary" prepend-icon="mdi-open-in-new"
                            :href="$route('app-site.events.tickets.show', { ticket_id: ticket.uuid, download: true, pdf: true })"
                            block target="_blank"></v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
